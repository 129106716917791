<template>
  <div>
    <ContextMenu ref="cm" :model="menuModel" data-testid="order-context-menu" />
    <Card>
      <template #content>
        <ColumnOptionsMenu :label="t('order.header')" :itemsList="items"></ColumnOptionsMenu>
        <DataTable
          ref="orderSearchResult"
          :value="orders"
          v-model:contextMenuSelection="selectedOrder"
          dataKey="id"
          :autoLayout="true"
          responsiveLayout="scroll"
          selectionMode="single"
          @row-select="onRowSelected"
          @row-dblclick="onRowDblClick"
          v-model:selection="selectedOrder"
          :sortOrder="sortOrder"
          :sortField="sortField"
          class="c-compact-datatable"
          :loading="loading"
          :stripedRows="true"
          removableSort
          :paginator="true"
          :rows="pageSize"
          :lazy="true"
          :totalRecords="totalHits"
          :rowsPerPageOptions="[50, 100]"
          :currentPageReportTemplate="
            t('common.current-page-template', {
              first: '{first}',
              last: '{last}',
              totalRecords: '{totalRecords}',
            })
          "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          @sort="onSort"
          @page="onPage"
          tabindex="0"
          data-testid="order-search-result"
          :rowClass="addGlobalSearchClass"
          @rowContextmenu="onRowContextMenu"
          scrollable
          scrollHeight="75vh"
          :resizableColumns="true"
          columnResizeMode="fit"
          :reorderable-columns="true"
          @column-reorder="onColumnReorder"
          @column-resize-end="onColumnResizeEnd"
          :key="renderKey as unknown as number"
        >
          <Column
            v-for="col of selectedColumnsComputed as unknown as DataTableColumn[]"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            :columnKey="col.field"
            :class="col.class"
            :sortable="col.sortable"
            :pt="{
              headerCell: {
                id: col.field,
                class: col.field,
              },
            }"
            :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
          >
            <template #body="{ data, field, index }">
              <template v-if="col.field === OrderSearchColumns.Registered">
                <span>{{ d(data.registered, "long") }}</span>
              </template>
              <template v-else-if="col.field === OrderSearchColumns.OrderNumber">
                <div class="flex justify-content-between align-items-center">
                  <span
                    :data-testId="`order-number-text-${index}`"
                    class="c-order-number-text"
                    @click="onOrderNumberClicked(data)"
                    >{{ data.orderNumber }}</span
                  >
                </div>
              </template>
              <template v-else-if="col.field === OrderSearchColumns.OrderReference">
                {{ data.orderReference }}
              </template>
              <template v-else-if="col.field === OrderSearchColumns.CustomerName">
                {{ data.customer.customerName }}
              </template>
              <template v-else-if="col.field === OrderSearchColumns.CustomerNumber">
                {{ data.customer.customerNumber }}
              </template>
              <template v-else-if="col.field === OrderSearchColumns.OrderStatus">
                {{ t(`order.status.${data.orderStatus.toLowerCase()}`) }}
              </template>
              <template v-else-if="col.field === OrderSearchColumns.PDF">
                <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="$emit('downloadPdf', data.id)" />
              </template>
              <template v-else>
                {{ data[field] }}
              </template>
            </template>
          </Column>

          <template #empty v-if="!loading">
            <span>{{ t("order.search.total-hits", 0) }}</span>
          </template>
        </DataTable>
      </template>
    </Card>
    <ColumnChooser
      v-model:visibleDialog="visible"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      :onSelectAllChange="onSelectAllChange"
      :selectAll="selectAll"
      @resetColumns="resetColumns"
      @columnSelected="onColumnSelected"
      @columnUnselected="onColumnUnselected"
    />
  </div>
  <PrintDialog v-model:visibleDialog="showPrintModal" :order="selectedOrder" v-if="showPrintModal" />
</template>
<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { SearchOrder } from "@/models/search/order/SearchOrder";
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, ref, watch } from "vue";

import PrintDialog from "./search/PrintDialog.vue";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { useAuth } from "@cumulus/event-bus";
import { OrderSearchColumns } from "@/models/order/OrderSearchColumns";

import { ColumnOptionsMenu, ColumnChooser, useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { t, d } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const orderSearchResult = ref();
const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const cm = ref();
const visible = ref(false);

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};
const showPrintModal = ref(false);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};
const selectedOrder = ref<SearchOrder>(new SearchOrder());

const props = defineProps<{
  orders: SearchOrder[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "downloadPdf", value: string): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "orderRefresh"): void;
}>();

const orderSearchColumns = [
  { field: "registered", header: t("order.search.registered"), sortable: true },
  { field: "ordernumber", header: t("order.order-number.label"), sortable: true },
  { field: "orderReference", header: t("order.search.order-reference"), sortable: true },
  {
    field: "customer.customerName",
    header: t("order.search.customer-name"),
    sortable: true,
  },
  {
    field: "customer.customerNumber",
    header: t("order.search.customer-number"),
    sortable: true,
  },
  { field: "orderStatus", header: t("order.search.order-status"), sortable: true },

  { field: "pdf", header: "PDF", class: "c-pdf-icon text-center", sortable: false },
];
const { getUser } = useAuth();

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("orderSearch", orderSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== OrderSearchColumns.Registered &&
      col.field !== OrderSearchColumns.OrderNumber &&
      col.field !== OrderSearchColumns.OrderReference,
  );
});
const addGlobalSearchClass = (data: SearchOrder) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedOrder.value?.id === data?.id },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchOrder(event.data as SearchOrder);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  openSearchOrder(event.data as SearchOrder);
};

watch([() => props.orders, () => props.loading], ([orders, loading]) => {
  if (loading === false && orders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const openSearchOrder = (searchOrder: SearchOrder) => {
  searchOrder != null && searchOrder.id !== emptyUuid
    ? router.push({ name: "order-edit", params: { id: searchOrder.id } })
    : toast.add({
        severity: "warn",
        summary: t("order.search.toast.open-order-failed"),
        detail: t("order.search.toast.no-order-selected"),
      });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const focusSearchResult = (id = "") => {
  if (props.orders.length > 0 && orderSearchResult.value) {
    const element = id === "" ? null : orderSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    orderSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onOrderNumberClicked = (order: SearchOrder) => {
  selectedOrder.value = order;
  openSearchOrder(order);
};

const items = ref([
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickOrderRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
]);

const onClickOrderRefresh = () => {
  emit("orderRefresh");
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? orderSearchColumns
    : orderSearchColumns.filter(
        (c) =>
          c.field === OrderSearchColumns.Registered ||
          c.field === OrderSearchColumns.OrderNumber ||
          c.field === OrderSearchColumns.OrderReference,
      );
};
</script>
<style scoped lang="scss">
.c-order-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
  @media (max-width: 992px) {
    .p-paginator-first {
      margin: 0;
    }
    .p-paginator-current {
      display: none;
    }
  }
}
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
:deep(.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  .p-column-title {
    visibility: hidden;
  }
}
:deep(.p-multiselect) {
  border: none;
}
</style>
