<template>
  <Teleport to="#c-mobile-toolbar" v-if="isMobile">
    <PrimeButton class="p-button-text" @click="emit('onCancel')" data-testid="btn-cancel-mobile">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.cancel") }}</span>
    </PrimeButton>
    <PrimeButton
      class="p-button-text"
      @click="emit('onCommit')"
      data-testid="btn-save-mobile"
      :disabled="!props.isOrderChanged || props.isSaving"
    >
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.save") }}</span>
    </PrimeButton>
  </Teleport>

  <Teleport to="#c-more-options-menu" v-if="isMobile">
    <PrimeButton class="p-button-text" @click="emit('toggleSidebarClicked')" data-testid="btn-sidebar">
      <i class="pi pi-info"></i>
      <span class="c-mobile-toolbar-text">{{ props.showSidebar ? "Hide panel" : "Show panel" }}</span>
    </PrimeButton>
  </Teleport>
  <div class="c-toolbar-wrapper bg-surface-100">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            v-if="allowSave"
            class="c-circular-button mr-4"
            @click="emit('onCommit')"
            data-testid="btn-save"
            :disabled="!props.isOrderChanged || props.isSaving"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          class="c-circular-button mr-4"
          @click="emit('onCancel')"
          data-testid="btn-cancel"
          :disabled="props.isSaving"
        >
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          v-if="allowDelete"
          class="c-circular-button mr-4"
          @click="onConfirmDelete"
          data-testid="btn-delete"
          :disabled="props.isSaving"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>

        <PrimeButton
          v-if="!advancedProductSearch"
          class="c-circular-button c-tool-material mr-4"
          @click="advancedProductSearch = true"
          data-testid="btn-advanced-search"
        >
          <span class="pi c-default-button c-circular-icon material-symbols-outlined"> manage_search </span>
          <span class="px-4">{{ t("order.search-mode") }}</span>
        </PrimeButton>

        <PrimeButton
          v-else
          class="c-circular-button c-tool-material mr-4"
          @click="advancedProductSearch = false"
          data-testid="btn-advanced-search"
        >
          <span class="pi c-default-button c-circular-icon material-symbols-outlined"> shopping_bag </span>
          <span class="px-4">{{ t("order.view-order") }}</span>
        </PrimeButton>

        <PrimeButton
          class="c-circular-button c-tool-material"
          @click="emit('toggleSidebarClicked')"
          data-testid="btn-sidebar"
        >
          <span class="pi c-default-button c-circular-icon material-symbols-outlined">
            {{ props.showSidebar ? "zoom_in_map" : "zoom_out_map" }}
          </span>
          <span class="px-4">{{ props.showSidebar ? t("order.hide-panel") : t("order.show-panel") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="props.unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToOrderSearch')"
    @saveClicked="emit('onCommit')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { OrderStatus } from "@/models/order/OrderStatus";
import { computed } from "vue";
import { UnsavedChangesDialog } from "@cumulus/components";
import { useMobile } from "@/utils/MobileService";

const { t } = useI18n();
const confirm = useConfirm();
const { isMobile } = useMobile();

const props = defineProps<{
  showSidebar: boolean;
  editMode: boolean;
  unsavedChangesDialogVisible: boolean;
  isOrderChanged: boolean;
  isSaving: boolean;
  orderStatus: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "onCommit"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
  (e: "stayOnPage"): void;
  (e: "routeToOrderSearch"): void;
  (e: "toggleSidebarClicked"): void;
}>();

const advancedProductSearch = defineModel<boolean>("advancedProductSearch", { required: true });

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("order.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const allowDelete = computed<boolean>(() => {
  return props.editMode && props.orderStatus === OrderStatus.Open;
});

const allowSave = computed<boolean>(() => {
  return (props.editMode && props.orderStatus !== OrderStatus.Delivered) || !props.editMode;
});

const saveButtonTooltip = computed(() => {
  return props.isOrderChanged ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-tool-material .material-symbols-outlined {
  font-size: 20px;
  padding: 2px;
  font-weight: 300;
}

.c-header-icon-container {
  background: transparent;
}

.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
