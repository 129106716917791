<template>
  <DataTable :value="parcels">
    <Column field="parcelNumber" header="Parcel">
      <template #body="{ data, field }">
        <InputNumber disabled v-model="data[field]" inputClass="w-12" :placeholder="data[field].toString()" />
      </template>
    </Column>
    <Column field="weight" :header="t('common.weight')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :minFractionDigits="0"
          :maxFractionDigits="2"
          :data-testid="`parcel-weight-${index}`"
          @update:model-value="emit('recalculateBring')"
        />
      </template>
    </Column>
    <Column field="length" :header="t('common.length')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-length-${index}`"
          @update:model-value="emit('recalculateBring')"
        />
      </template>
    </Column>
    <Column field="width" :header="t('common.width')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-width-${index}`"
          @update:model-value="emit('recalculateBring')"
        />
      </template>
    </Column>
    <Column field="height" :header="t('common.height')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-height-${index}`"
          @update:model-value="emit('recalculateBring')"
        />
      </template>
    </Column>
    <Column>
      <template #body="{ index }">
        <PrimeButton
          severity="danger"
          :disabled="parcels.length <= 1"
          icon="pi pi-trash"
          class="c-danger-button"
          @click="deleteParcel(index)"
        />
      </template>
    </Column>
    <template #footer>
      <div class="flex mt-2">
        <PrimeButton
          :label="t('common.add-parcel')"
          icon="pi pi-plus-circle"
          @click="addParcel"
          class="p-button-text c-black-text"
          iconClass="c-success-icon"
          data-testid="btn-add-parcel"
        />
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Parcel } from "@/models/bring/Parcel";

const { t } = useI18n();
const addParcel = () => {
  parcels.value = [...parcels.value, new Parcel((parcels.value[parcels.value.length - 1]?.parcelNumber ?? 0) + 1)];
  emit("recalculateBring");
};
const emit = defineEmits<{
  (e: "recalculateBring"): void;
}>();

const parcels = defineModel<Parcel[]>("parcels", {
  required: true,
  default: [new Parcel(1)],
});

const deleteParcel = (index: number) => {
  if (parcels.value.length <= 1) return;
  parcels.value.splice(index, 1);
  parcels.value.forEach((x, index) => (x.parcelNumber = index + 1));
  emit("recalculateBring");
};
</script>
<style>
.pi.pi-plus-circle.c-success-icon {
  color: var(--p-button-success-background);
}
.c-black-text span {
  color: black;
}
</style>
